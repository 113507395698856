@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: Poppins;
  src: url('./content/fonts/Poppins-Regular.ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
}

:root {
  --white: #fff;
  --black: #000;
  --darkgray: #252525;
  --gray: #8a8a8a;
  --lightgray: #e5e5e5;
  --purple: #44489a;
  --primary: #44489a;
  --blue: #5cabff;
  --green: #00bf63;
  --pink: #924adb;
  --stats: #c1ff72;
  --red: #ff5757;
  --sprintBg: #252525;
}

.dark-mode {
  --white: #000;
  --black: #fff;
  --darkgray: #eee;
  --gray: #8a8a8a;
  --lightgray: #e5e5e5;
  --stats: #ff5757;
  --sprintBg: #8a8a8a;
}

body {
  background-color: #eee;
}

body.dark-mode {
  background-color: #252525;
}


/* Layouts gerais */

.input {
  @apply w-full px-4 py-2 border-none bg-lightgray text-black rounded-[25px] outline-[var(--primary)]
}

.small-button {
  @apply px-6 py-2 rounded-[10px] border-[transparent] border-[2px] flex justify-between items-center text-white cursor-pointer gap-4
}

.small-button:hover {
  @apply border-[var(--black)]
}

.big-button {
  @apply px-4 seis:px-8 h-[6rem] rounded-[15px] border-[transparent] border-[3px] text-white cursor-pointer 
}

.big-button.style1 {
  @apply flex mil:flex-col mil:justify-center mil:items-start seis:justify-between seis:items-center seis:flex-row flex-col justify-center overflow-hidden whitespace-nowrap
}

.big-button.style2 {
  @apply flex justify-between items-center overflow-hidden whitespace-nowrap
}

.big-button:hover {
  @apply border-[var(--black)]
}

.filter {
  @apply w-[14rem] px-2 py-1 bg-lightgray text-black rounded-[5px] border-[1px] border-[transparent] 
}

.filter:focus {
  @apply border-[var(--black)]
}

.mandatory::before {
  content: '* ';
  color: var(--red);
}

input[type="password"]::-ms-reveal {
  display: none;
}

/* ScrollBar */

.my-scrollbar {
  padding-right: 1rem;
}

/* Handle */
.my-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 100vw;
}

/* Handle */
.my-scrollbar::-webkit-scrollbar-thumb:active {
  background-color: #2f00ff;
  border-radius: 100vw;
}

/* Track */
.my-scrollbar::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 100vw;
}

.my-scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: .7rem;
}

.article-editor > div {
  width: 100% !important;
}
